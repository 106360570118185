import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import AddNew from "./AddNew";
import View from "./View";
import Edit from "./Edit";
import { BiArrowBack } from "react-icons/bi";

const Drawers = ({
  isOpen,
  onClose,
  drawerType,
  data,
  fetchSubCategories, // Renamed function
}) => {
  const renderDrawer = () => {
    switch (drawerType) {
      case "addNew":
        return <AddNew onClose={onClose} fetchSubCategories={fetchSubCategories}/>;
      case "show":
        return (
          <View
            selectedItem={data}
            onClose={onClose}
            // handleAddUpdateDeleteItem={handleAddUpdateDeleteItem}
          />
        );
      case "edit":
        return (
          <Edit
            selectedItem={data}
            onClose={onClose}
            fetchData={fetchSubCategories}
            // handleAddUpdateDeleteItem={handleAddUpdateDeleteItem} // Renamed function
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Button
              leftIcon={<BiArrowBack />}
              onClick={onClose}
              variant="ghost"
              alignItems="center"
              justifyContent="center"
            />
            {drawerType === "addNew" && "Add New Category"}
            {drawerType === "show" && "Show Category Details"}{" "}
            {drawerType === "edit" && "Edit Category Details"}{" "}
          </DrawerHeader>
          <DrawerBody>{renderDrawer()}</DrawerBody>
        </DrawerContent>
      </Drawer> */}
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {drawerType === "addNew" && "Add New Sub Category"}
            {drawerType === "show" && "Show Category Details"}{" "}
            {drawerType === "edit" && "Edit Sub Category Details"}{" "}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{renderDrawer()}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Drawers;
