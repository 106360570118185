import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Text,
  Textarea,
} from "@chakra-ui/react";
import MyBox from "../../../layout/MyBox";
import CustomBlog from "../../../components/CustomBlog";
import { BASE_URL } from "../../../api/constants";
import { getAllGallery, getAllSections } from "../../../api/childSection";
import LoaderMini from "../../../components/LoaderMini";

const View = ({ selectedItem }) => {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [galleryPreviewImages, setGalleryPreviewImages] = useState({});

  const [gallery, setGallery] = useState([]);
  useEffect(() => {
    const getSection = async () => {
      try {
        const result = await getAllSections(selectedItem.id);
        console.log("sections", result); // Ensure you're passing the correct ID
         // Ensure you're passing the correct ID
        setSections(result);
      } catch (error) {
        console.error("Error fetching sections:", error);
      } finally {
        setLoading(false);
      }
    };
    const getGallery = async () => {
      try {
        console.log(selectedItem.id);
        const result = await getAllGallery(selectedItem.id); 
        console.log("result", result); // Ensure you're passing the correct ID
        setGallery(result);
      } catch (error) {
        console.error("Error fetching gallery:", error);
      } finally {
        setLoading(false);
      }
    };

    getSection();
    getGallery();
  }, [selectedItem]);

  if (loading) {
    return <LoaderMini/>;
  }

  return (
    <Box mx={10}>
      <Flex gap={4} wrap="wrap">
        <MyBox>
          <Image
            src={
              `${BASE_URL}/api/child-category/image/${selectedItem.id}}` ||
              "https://placehold.co/600x400?text=No Image"
            }
            alt="Image"
            boxSize="200px"
            objectFit="cover"
          />
        </MyBox>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={4} w="100%">
          <MyBox>
            <Text fontWeight="bold">Parent Category:</Text>
            <Text>{selectedItem.parent_name || "No Category"}</Text>
          </MyBox>
          <MyBox>
            <Text fontWeight="bold">Title:</Text>
            <Text>{selectedItem.name || "No Title"}</Text>
          </MyBox>
          <MyBox>
            <Text fontWeight="bold">Slug:</Text>
            <Text>{selectedItem.slug || "No Slug"}</Text>
          </MyBox>
        </SimpleGrid>
        <MyBox w="100%">
          <Text fontWeight="bold">Description:</Text>
          <Textarea
            isReadOnly
            value={selectedItem.description || "No Description"}
          />
        </MyBox>
      </Flex>
      <MyBox>
        <Text fontWeight="semibold">Gallery Images</Text>
        {gallery.map((item, index) => (
          <Box key={index} p={4} rounded="lg" shadow="md" mb={4} flex={1}>
            <Flex align="center" justify="center" gap={1}>
              <Box>
                {/* <Input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileChangeInGallery(e, index)}
                      ref={(el) => (galleryFileInputRefs.current[index] = el)}
                      display="none"
                    /> */}
                <Image
                src={
                    `${BASE_URL}/api/child-category-section-image/image/${item.id}`
                }
                alt="Image"
                boxSize="200px"
                objectFit="cover"
              />
              </Box>
              <Textarea placeholder="Description" value={item.description} isDisabled={true} />
            </Flex>
          </Box>
        ))}
      </MyBox>
      <Divider border="1px solid gray" my={4} />
      {sections.map((section, index) => (
        <MyBox key={index} mb={4} borderRadius="lg" shadow="lg">
          <Box mb={2}>
            <Text fontWeight="bold">Section {index + 1}:</Text>
            {/* <Text>ID: {section.id}</Text> */}
            <Text fontWeight="semibold">{section.title}</Text>
            <Text fontStyle="italic">{section.description}</Text>
          </Box>
          <Box mb={2}>
            {section.videoLink ? (
              <iframe
                src={section.videoLink}
                alt="Video"
                width="600"
                height="400"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            ) : (
              <Image
                src={
                    `${BASE_URL}/api/child-category-section/image/${section.id}`
                }
                alt="Image"
                boxSize="200px"
                objectFit="cover"
              />
            )}
          </Box>
        </MyBox>
      ))}
    </Box>
  );
};

export default View;
