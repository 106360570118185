import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Textarea,
  useColorModeValue,
  useToast,
  Text,
} from "@chakra-ui/react";
import MyBox from "../../../layout/MyBox";
import { getAllCategory } from "../../../api/category";
import { editSubCategory } from "../../../api/subcategories";
import { BASE_URL } from "../../../api/constants";
import { PiPlus } from "react-icons/pi";
import { MdDelete } from "react-icons/md";
import CustomBlog from "../../../components/CustomBlog";
import {
  addChildCategoryGallery,
  addChildCategorySection,
  deleteChildCategoryGallery,
  deleteChildCategorySection,
  editChildCategoryGallery,
  editChildCategorySection,
  getAllGallery,
  getAllSections,
} from "../../../api/childSection";

const Edit = ({ onClose, selectedItem, fetchData }) => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const fileInputRef = useRef();
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast(); // Initialize useToast hook
  const sectionFileInputRefs = useRef([]);
  const [sectionPreviewImages, setSectionPreviewImages] = useState({});
  const [galleryPreviewImages, setGalleryPreviewImages] = useState({});

  const galleryFileInputRefs = useRef([]);

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    setImage(selectedItem.img || null);
    setPreviewImage(selectedItem.img || null);
    setTitle(selectedItem.name || "");
    setSlug(selectedItem.slug || "");
    setSelectedCategory(selectedItem.parent_id || "");
    setDescription(selectedItem.description || "");
  }, [selectedItem]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const subCategoryData = {
        id: selectedItem.id, // Include the subcategory ID for updating
        image: image,
        parent_id: selectedCategory,
        name: title,
        description: description,
        slug: slug,
      };
      // const response = await editSubCategory(subCategoryData);

      if (sections && sections.length > 0) {
        const promises = [];
        sections.forEach((section, index) => {
          const formData = new FormData();
          if (section.image) {
            formData.append(`image`, section.image);
          } else {
            formData.append(`videoLink`, section.videoLink); // Assuming you have a field named `video` in your API
          }
          formData.append(`title`, section.title);
          formData.append(`description`, section.description);
          formData.append(`childCategoryId`, selectedItem.id);
          if (section.id) {
            const promise = editChildCategorySection(formData, section.id);
            promises.push(promise);
          } else {
            const promise = addChildCategorySection(formData);
            promises.push(promise);
          }
        });
        try {
          const responses = await Promise.all(promises);
        } catch (error) {
          console.error("Error:aaaaaaaaaaaaaaaaaaaaaaaaaa", error);
        }
      }

      if (gallery && gallery.length > 0) {
        const promises = [];
        gallery.forEach((galleries, index) => {
          const formData = new FormData();
          formData.append(`image`, galleries.image);
          formData.append(`description`, galleries.description);
          formData.append(`childCategorySectionId`, selectedItem.id);
          console.log("gallery", galleries);
          formData.delete('img');

          if (galleries.id) {
            const promise = editChildCategoryGallery(formData, galleries.id);
            promises.push(promise);
          } else {
            formData.delete('img');
            console.log("aaaaaaaaaaaaaaaa", galleries);

            const promise = addChildCategoryGallery(formData);
            promises.push(promise);
          }
        });

        try {
          const responses = await Promise.all(promises);
        } catch (error) {
          console.error("Error:", error);
        }
      }
      setIsLoading(false); // Set loading state to false
      toast({
        title: "Subcategory Updated",
        status: "success",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      fetchData();
      onClose();
    } catch (error) {
      console.error("Error updating subcategory:", error);
      setIsLoading(false); // Set loading state to false
      toast({
        title: "Error",
        description: "Failed to update subcategory. Please try again.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      // Handle error, show error message, etc.
    }
  };


  const handleAddSection = () => {
    const newSection = {
      image: null,
      video: null,
      videoLink: "",
    };
    setSections([...sections, newSection]);
  };
  const handleAddGallery = () => {
    const newGallery = {
      img: null,
      description: "",
    };
    setGallery([...gallery, newGallery]);
  };

  const handleFileChangeInSection = (event, index) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type.includes("image")) {
        const updatedSections = [...sections];
        updatedSections[index].image = selectedFile;
        setSections(updatedSections);
      } else if (selectedFile.type.includes("video")) {
        const updatedSections = [...sections];
        updatedSections[index].video = selectedFile;
        setSections(updatedSections);
      } else {
        const updatedSections = [...sections];
        updatedSections[index].video = selectedFile;
        setSections(updatedSections);
      }
    }
  };
  const handleFileChangeInGallery = (event, index) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const updatedGallery = [...gallery];
      updatedGallery[index].image = selectedFile;
      setGallery(updatedGallery);
    }
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setImage(selectedImage);
      setPreviewImage(URL.createObjectURL(selectedImage));
    }
  };
  const handleRemoveSection = async (indexToRemove, id) => {
    const updatedSections = sections.filter(
      (_, index) => index !== indexToRemove
    );
    setSections(updatedSections);
    const response = await deleteChildCategorySection(id);
  };
  const handleRemoveGallery =  async (indexToRemove, id) => {
    const updatedGallery = gallery.filter(
      (_, index) => index !== indexToRemove
    );
    setGallery(updatedGallery);
    const response = await deleteChildCategoryGallery(id);

  };

  const handleSwitchChange = (index) => {
    const updatedSections = [...sections];
    const currentSection = updatedSections[index];

    if (currentSection.videoLink == null) {
      currentSection.videoLink = "";
    } else {
      currentSection.videoLink = null;
      setSectionPreviewImages((prevState) => {
        const newState = { ...prevState };
        delete newState[index];
        return newState;
      });
    }
    currentSection.isImageUpload = !currentSection.isImageUpload;
    setSections(updatedSections);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Fetch categories from API
        const fetchedCategories = await getAllCategory();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        // Fetch categories from API
        const fetchedSections = await getAllSections(selectedItem.id);

        setSections(fetchedSections);
      } catch (error) {
        console.error("Error fetching Sections:", error);
      }
    };

    const getGallery = async () => {
      try {
        console.log(selectedItem.id);
        const result = await getAllGallery(selectedItem.id);
        console.log("result", result); // Ensure you're passing the correct ID
        setGallery(result);
      } catch (error) {
        console.error("Error fetching gallery:", error);
      }
    };
    getGallery();

    fetchSections();
  }, []);



  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleSectionImageClick = (index) => {
    sectionFileInputRefs.current[index].click();
  };

  const handleGalleryImageClick = (index) => {
    galleryFileInputRefs.current[index].click();
  };

  const handleCategorySelect = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <Box mx={10}>
      <Flex gap={4} wrap="wrap">
        <MyBox>
          <FormLabel>Upload Image</FormLabel>
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            display="none"
          />
          <Image
            src={
              image
                ? URL.createObjectURL(image)
                : `${BASE_URL}/api/child-category/image/${selectedItem.id}`
            }
            alt="Placeholder"
            boxSize="200px"
            objectFit="cover"
            cursor="pointer"
            onClick={handleImageClick}
          />
        </MyBox>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={4} w="100%">
          <MyBox>
            <FormControl mt={4} isRequired>
              <FormLabel>Parent Category</FormLabel>
              <Select
                placeholder="Select parent category"
                value={selectedCategory}
                onChange={handleCategorySelect}
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </MyBox>
          <MyBox>
            <FormControl mt={4} isRequired>
              <FormLabel>Title</FormLabel>
              <Input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
          </MyBox>
          <MyBox>
            <FormControl mt={4} isRequired>
              <FormLabel>Slug</FormLabel>
              <Input
                type="text"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
              />
            </FormControl>
          </MyBox>
        </SimpleGrid>
        <MyBox w="100%">
          <FormControl isRequired>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
        </MyBox>
      </Flex>
      <FormControl mt={2}>
        <MyBox>
          <FormLabel>Upload Gallery Images</FormLabel>
          {gallery.map((item, index) => (
            <Box key={index} p={4} rounded="lg" shadow="md" mb={4} flex={1}>
              <Flex align="center" justify="center" gap={1}>
                <Box>
                  <FormControl isRequired>
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileChangeInGallery(e, index)}
                      ref={(el) => (galleryFileInputRefs.current[index] = el)}
                      display="none"
                    />
                    <Image
                      src={
                        item.image
                          ? URL.createObjectURL(item.image)
                          :
                          `${BASE_URL}/api/child-category-section-image/image/${item.id}`
                      }
                      alt="Placeholder"
                      boxSize="100px"
                      objectFit="cover"
                      cursor="pointer"
                      onClick={() => handleGalleryImageClick(index)}
                    />
                  </FormControl>
                </Box>
                <FormControl mt={2} isRequired>
                  <Textarea
                    placeholder="Description"
                    value={item.description}
                    onChange={(e) => {
                      const updatedGallery = [...gallery];
                      updatedGallery[index].description = e.target.value;
                      setGallery(updatedGallery);
                    }}
                  />
                </FormControl>
              </Flex>
              <Button
                colorScheme="red"
                size="sm"
                onClick={() => handleRemoveGallery(index,item.id )}
                leftIcon={<MdDelete />}
                variant="ghost"
              >
                Remove
              </Button>
            </Box>
          ))}
          <Flex justify="center">
            <Button
              onClick={handleAddGallery}
              variant="outline"
              size="sm"
              color="green"
              leftIcon={<PiPlus />}
            >
              Add Images
            </Button>
          </Flex>
        </MyBox>
      </FormControl>
      <Divider border="1px solid gray" my={4} />
      {sections.map((section, index) => (
        <MyBox key={index} p={4} rounded="lg" shadow="md" mb={4}>
          <Flex justify="space-between" align="center" mb={2}>
            <Text fontSize="lg" fontWeight="bold">
              Section {index + 1}
            </Text>
            <Button
              colorScheme="red"
              size="sm"
              onClick={() => handleRemoveSection(index, section.id)}
              leftIcon={<MdDelete />}
              variant="ghost"
            >
              Remove
            </Button>
          </Flex>
          <FormControl display="flex" alignItems="center" mb={2}>
            <FormLabel htmlFor={`imageOrVideoSwitch${index}`} mb="0" mr={2}>
              {section.isImageUpload ? "Enter Video URL" : "Upload Image"}
            </FormLabel>
            <Switch
              id={`imageOrVideoSwitch${index}`}
              isChecked={section.isImageUpload}
              onChange={() => handleSwitchChange(index)}
              colorScheme="green"
              size="sm"
            />
          </FormControl>
          <Divider />
          <FormControl mt={2} isRequired>
            {section.videoLink == "null" || section.videoLink == "" ? (
              <FormControl isRequired>
                <FormLabel>Upload Image</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChangeInSection(e, index)}
                  ref={(el) => (sectionFileInputRefs.current[index] = el)}
                  display="none"
                />
                <Image
                  src={
                    section.image
                      ? URL.createObjectURL(section.image)
                      : `${BASE_URL}/api/child-category-section/image/${section.id}`
                  }
                  alt="Placeholder"
                  boxSize="200px"
                  objectFit="cover"
                  cursor="pointer"
                  onClick={() => handleSectionImageClick(index)}
                />
              </FormControl>
            ) : (
              <Input
                type="text"
                placeholder="Enter video URL..."
                value={section.videoLink}
                onChange={(e) => {
                  const updatedSections = [...sections];
                  updatedSections[index].videoLink = e.target.value;
                  setSections(updatedSections);
                }}
              />
            )}
          </FormControl>
          <FormControl mt={2} isRequired>
            <Input
              type="text"
              placeholder="Title"
              value={section.title}
              onChange={(e) => {
                const updatedSections = [...sections];
                updatedSections[index].title = e.target.value;
                setSections(updatedSections);
              }}
            />
          </FormControl>
          <FormControl mt={2} isRequired>
            <Textarea
              placeholder="Description"
              value={section.description}
              onChange={(e) => {
                const updatedSections = [...sections];
                updatedSections[index].description = e.target.value;
                setSections(updatedSections);
              }}
            />
          </FormControl>
        </MyBox>
      ))}
      <Flex justify="center">
        <Button
          onClick={handleAddSection}
          variant="outline"
          size="sm"
          color="green"
          leftIcon={<PiPlus />}
        >
          Add Section
        </Button>
      </Flex>
      <Flex justify="end">
        <Button
          mt={4}
          colorScheme="primary"
          onClick={handleSubmit}
          isLoading={isLoading} // Set button loading state
          loadingText="Updating"
        >
          Edit
        </Button>
      </Flex>
    </Box>
  );
};

export default Edit;
