import React, { useState, useEffect } from "react";
import Page from "../../../layout/Page";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { HiDotsHorizontal } from "react-icons/hi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import {
  BiSearch,
  BiChevronLeft,
  BiChevronRight,
  BiShow,
  BiSearchAlt,
} from "react-icons/bi";
import Drawers from "./Drawers";
import DeleteAlert from "../../../components/DeleteAlert";
import { getAllSubCategory } from "../../../api/subcategories";
import { BASE_URL } from "../../../api/constants";
import { Link } from "react-router-dom";
import LoaderMini from "../../../components/LoaderMini";

const SubCategory = ({ sideBarWidth }) => {
  const [subCategories, setSubCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDrawerType, setSelectedDrawerType] = useState("");
  const [selectedItemData, setSelectedItemData] = useState(null);
  const bgColor = useColorModeValue("white", "gray.700");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const fetchSubCategories = async () => {
    try {
      const subCategoriesData = await getAllSubCategory();
      setSubCategories(subCategoriesData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      toast({
        title: "Error",
        description: "Failed to fetch subcategories.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (subCategories.length === 0) {
      fetchSubCategories();
    }
  }, []);

  const filteredItems = subCategories.filter(
    (item) =>
      item.name?.toLowerCase().includes(searchTerm) ||
      item.slug?.toLowerCase().includes(searchTerm) ||
      item.parent_id.toString().includes(searchTerm)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value?.toLowerCase();
    setSearchTerm(searchText);
  };

  const openDrawer = (drawerType, itemData) => {
    setSelectedDrawerType(drawerType);
    setSelectedItemData(itemData);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedDrawerType("");
    setSelectedItemData(null);
  };

  const handleDeleteClick = (item) => {
    setSelectedItemId(item.name);
    setSelectedItemData(item);
    setIsDeleteAlertOpen(true);
  };
  if (isLoading) {
    return <LoaderMini />;
  }
  return (
    <Page sideBarWidth={sideBarWidth}>
      <Heading mb={3}>Sub Categories</Heading>
      <Flex align="center" mb={4} justify="space-between">
        <Flex align="center" w="50%">
          <InputGroup w="100%" size={"sm"}>
            <InputLeftElement
              pointerEvents="none"
              color="gray.400"
              fontSize="1.2em"
              ml={2}
            >
              <BiSearchAlt />
            </InputLeftElement>
            <Input
              placeholder="Search by name, parent category or slug"
              value={searchTerm}
              onChange={handleSearchChange}
              borderRadius="0.3rem"
              py={2}
              pl={10}
              pr={3}
              fontSize="md"
              mr={4}
              _placeholder={{ color: "gray.400" }}
            />
          </InputGroup>
        </Flex>
        <Flex align="center">
          <Button
            variant="solid"
            colorScheme="primary"
            size="sm"
            onClick={() => openDrawer("addNew")}
            as={Link}
            to="/categories/sub-category/new"
          >
            New Sub Category
          </Button>
        </Flex>
      </Flex>
      <Flex
        wrap="wrap"
        gap={2}
        my={4}
        justify={{ base: "center", md: "start" }}
      >
        {currentItems.map((item, index) => (
          <Box
            bg={bgColor}
            px={4}
            pb={6}
            borderRadius="lg"
            shadow="xl"
            key={index}
          >
            <Flex justify="end">
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<HiDotsHorizontal />}
                  variant="ghost"
                  size="sm"
                />
                <MenuList>
                  <MenuItem
                    icon={<BiShow />}
                    onClick={() => openDrawer("show", item)}
                  >
                    Show
                  </MenuItem>
                  <MenuItem
                    icon={<FiEdit />}
                    onClick={() => openDrawer("edit", item)}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    icon={<FiTrash2 />}
                    onClick={() => handleDeleteClick(item)}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Stack textAlign="start">
              <Img
                src={`${BASE_URL}/api/child-category/image/${item.id}}`}
                boxSize="9rem"
                objectFit="cover"
                borderRadius="md"
              />
              <Box>
                <Text fontWeight="semibold">{item.name}</Text>
              </Box>
              <Text fontWeight="light" fontSize="sm" color="gray">
                [{item.slug}]
              </Text>
            </Stack>
          </Box>
        ))}
      </Flex>
      <Flex justify="space-between" mt={4} align="center">
        <Box>
          <IconButton
            icon={<BiChevronLeft />}
            isDisabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            aria-label="Previous Page"
          />
          <IconButton
            icon={<BiChevronRight />}
            isDisabled={indexOfLastItem >= filteredItems.length}
            onClick={() => handlePageChange(currentPage + 1)}
            ml={2}
            aria-label="Next Page"
          />
        </Box>
        <Text fontSize="smaller">
          Page {currentPage} of {Math.ceil(filteredItems.length / itemsPerPage)}
        </Text>
      </Flex>
      <Drawers
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        drawerType={selectedDrawerType}
        data={selectedItemData}
        fetchSubCategories={fetchSubCategories}
      />
      <DeleteAlert
        isOpen={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        HeaderText={"Delete Category"}
        BodyText={`Are you sure you want to delete ${selectedItemId}?`}
        fetchData={fetchSubCategories}
        data={selectedItemData}
      />
    </Page>
  );
};

export default SubCategory;
