import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import Add from "./Add";
import Edit from "./Edit";
import View from "./View";

const Drawers = ({
  isOpen,
  onClose,
  drawerType,
  data,
  handleAddUpdateDeleteUser,
  fetchUsers
}) => {
  const renderDrawer = () => {
    switch (drawerType) {
      case "addNew":
        return <Add onClose={onClose} fetchUsers={fetchUsers} />;
      case "show":
        return <View selectedItem={data} onClose={onClose} />;
      case "edit":
        return <Edit selectedItem={data} onClose={onClose}  fetchUsers={fetchUsers} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Button
              leftIcon={<BiArrowBack />}
              onClick={onClose}
              variant="ghost"
              alignItems="center"
              justifyContent="center"
            />
            {drawerType === "addNew" && "Add New User"}
            {drawerType === "show" && "View User Details"}
            {drawerType === "edit" && "Edit User Details"}
          </DrawerHeader>
          <DrawerBody>{renderDrawer()}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Drawers;
