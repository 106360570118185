import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
} from "@chakra-ui/react";
import AddNew from "./AddNew";
import View from "./View";
import Edit from "./Edit";
import { BiArrowBack } from "react-icons/bi";

const Drawers = ({
  isOpen,
  onClose,
  drawerType,
  data,
  fetchData
}) => {
  const renderDrawer = () => {
    switch (drawerType) {
      case "addNew":
        return <AddNew onClose={onClose} fetchData={fetchData} />;
      case "show":
        return (
          <View
            selectedItem={data}
            onClose={onClose}
            // handleAddUpdateDeleteItem={handleAddUpdateDeleteItem}
          />
        );
      case "edit":
        return (
          <Edit
            selectedItem={data}
            onClose={onClose}
            fetchData={fetchData}
            // handleAddUpdateDeleteItem={handleAddUpdateDeleteItem} // Renamed function
          />
        );
      default:
        return null;
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Button
            leftIcon={<BiArrowBack />}
            onClick={onClose}
            variant="ghost"
            alignItems="center"
            justifyContent="center"
          />
          {drawerType === "addNew" && "Add New Category"} {/* Updated text */}
          {drawerType === "show" && "Show Category Details"}{" "}
          {/* Updated text */}
          {drawerType === "edit" && "Edit Category Details"}{" "}
          {/* Updated text */}
        </DrawerHeader>
        <DrawerBody>{renderDrawer()}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Drawers;
