import { Box, FormLabel, Img, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import MyBox from "../../../layout/MyBox";

const View = ({ selectedItem, onClose }) => {
  const [image, setImage] = useState(selectedItem?.img || null);
  const [title, setTitle] = useState(selectedItem?.title || "");
  const [slug, setSlug] = useState(selectedItem?.slug || "");
  const [previewImage, setPreviewImage] = useState(selectedItem?.img || null);
  return (
    <Box>
      <Stack spacing={4}>
        <MyBox>
          <Img
            src={previewImage}
            alt="Placeholder"
            boxSize="200px"
            objectFit="cover"
          />
        </MyBox>

        <MyBox>
          <Text fontWeight="semibold">Title</Text>
          <Text>{title}</Text>
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">Slug</Text>
          <Text>{slug}</Text>
        </MyBox>
      </Stack>
    </Box>
  );
};

export default View;
