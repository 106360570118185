import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import MyBox from "../../layout/MyBox";
import Page from "../../layout/Page";
import Drawers from "./Drawers";
import DeleteAlert from "../../components/DeleteAlert";
import { getAllOffices, addOffice, deleteOffice } from "../../api/office";
import { HiDotsVertical } from "react-icons/hi";
import { BiShow } from "react-icons/bi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import LoaderMini from "../../components/LoaderMini";

const Office = ({ sideBarWidth }) => {
  const [addresses, setAddresses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteAddressId, setDeleteAddressId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      const response = await getAllOffices();

      setAddresses(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const filteredAddresses = addresses.filter((address) => {
    const matchesSearch =
      address.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      address.contactNumber.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [drawerType, setDrawerType] = useState("");
  const [drawerData, setDrawerData] = useState(null);

  const openDrawer = (type, data = null) => {
    setDrawerType(type);
    setDrawerData(data);
    onOpen();
  };

  const handleDeleteAddress = (id) => {
    setDeleteAddressId(id);
  };

  const handleConfirmDeleteAddress = async () => {
    try {
      await deleteOffice(deleteAddressId);
      setAddresses(
        addresses.filter((address) => address.id !== deleteAddressId)
      );
      onCloseDeleteConfirmation();
    } catch (error) {
      console.error("Error deleting address:", error);
    }
  };

  const onCloseDeleteConfirmation = () => {
    setDeleteAddressId(null);
  };
  if (isLoading) {
    return <LoaderMini />;
  }
  return (
    <Page sideBarWidth={sideBarWidth}>
      <MyBox>
        <Flex justify="space-between" align="center" mb={4}>
          <Heading size="md">Address Management</Heading>
          <Button
            variant="solid"
            colorScheme="primary"
            size="sm"
            onClick={() => openDrawer("addNew")}
          >
            Add New Address
          </Button>
        </Flex>
        <Flex align="center" mb={4}>
          <Input
            flex="7"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            mr={2}
          />
        </Flex>
        <TableContainer>
          <Table variant="striped" size="sm" mt={4}>
            <Thead>
              <Tr>
                <Th>Map</Th>
                <Th>Title</Th>
                <Th>Location</Th>
                <Th>Contact Number</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredAddresses.map((address) => (
                <Tr key={address.id}>
                  <Box
                    as="iframe"
                    title="Google Map"
                    srcDoc={`<iframe src="${address.iframeLink}"></iframe>`}
                    width="100%"
                    height="300px"
                    style={{ border: 0 }}
                  />
                  <Td>{address.title}</Td>
                  <Td>{address.location}</Td>
                  <Td>{address.contactNumber}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<HiDotsVertical />}
                        variant="ghost"
                        size="sm"
                      />
                      <MenuList>
                        <MenuItem
                          icon={<BiShow />}
                          onClick={() => openDrawer("show", address)}
                        >
                          View
                        </MenuItem>
                        <MenuItem
                          icon={<FiEdit />}
                          onClick={() => openDrawer("edit", address)}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          icon={<FiTrash2 />}
                          onClick={() => handleDeleteAddress(address.id)}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </MyBox>
      <Drawers
        isOpen={isOpen}
        onClose={onClose}
        drawerType={drawerType}
        data={drawerData}
        fetchAddresses={fetchAddresses}
      />
      <DeleteAlert
        isOpen={deleteAddressId !== null}
        onClose={onCloseDeleteConfirmation}
        onConfirmDelete={handleConfirmDeleteAddress}
        HeaderText="Delete Address"
        BodyText="Are you sure you want to delete this address?"
      />
    </Page>
  );
};

export default Office;
