import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  VStack,
  HStack,
  useToast,
} from "@chakra-ui/react";
import MyBox from "../../layout/MyBox";
import { updateOffice } from "../../api/office"

const Edit = ({ selectedItem, onClose, fetchAddresses }) => {
  const [title, setTitle] = useState(selectedItem.title);
  const [location, setLocation] = useState(selectedItem.location);
  const [contactNumber, setContactNumber] = useState(selectedItem.contactNumber);
  const [iframeLink, setIframeLink] = useState(selectedItem.iframeLink);
  const [isValidIframe, setIsValidIframe] = useState(true);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async () => {
    // Perform validation here


    if (!title || !location || !contactNumber || !isValidIframe) {
      toast({
        title: "Fill all the details",
        status: "error",
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    try {
      setIsLoading(true);

      // Update office address with new values
      await updateOffice(selectedItem.id, {
        title,
        location,
        contactNumber,
        iframeLink,
      });

      toast({
        title: "Office address updated successfully!",
        status: "success",
        duration: 3000,
        position: 'top-right',
        isClosable: true,
      });

      fetchAddresses();
      setIsLoading(false);

      onClose();
    } catch (error) {
      console.error("Error updating office address:", error);
      toast({
        title: "An error occurred while updating office address",
        status: "error",
        duration: 5000,
        position: 'top-right',
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleIframeChange = (e) => {
    const url = e.target.value;
    setIsValidIframe(true);
    setIframeLink(url);
  };
  

  return (
    <MyBox p={4} borderRadius="md" boxShadow="md">
      <FormControl mb={4}>
        <FormLabel fontWeight={'semibold'} >Title</FormLabel>
        <Input
          placeholder="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {!title && (
          <Text color="red.500" fontSize="sm">
            Please enter a title
          </Text>
        )}
      </FormControl>
      <FormControl mb={4}>
        <FormLabel fontWeight={'semibold'}>Location</FormLabel>
        <Textarea
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        {!location && (
          <Text color="red.500" fontSize="sm">
            Please enter a location
          </Text>
        )}
      </FormControl>
      <FormControl mb={4}>
        <FormLabel fontWeight={'semibold'}>Contact Number</FormLabel>
        <Input
          placeholder="Contact Number"
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
        />
        {!contactNumber && (
          <Text color="red.500" fontSize="sm">
            Please enter a contact number
          </Text>
        )}
      </FormControl>
      <FormControl mb={4}>
        <FormLabel fontWeight={'semibold'}>Enter iframe link</FormLabel>
        <Input
          placeholder="Enter iframe link"
          value={iframeLink}
          onChange={handleIframeChange}
        />
        {!isValidIframe && (
          <Text color="red.500" fontSize="sm">
            Please provide a valid iframe link
          </Text>
        )}
      </FormControl>
      <FormControl mb={4}>
        <MyBox mt={4} p={2} borderRadius="md" boxShadow="md">
          {isValidIframe && (
            <MyBox
              as="iframe"
              title="Google Map"
              srcDoc={`<iframe src="${iframeLink}"></iframe>`}
              width="100%"
              height="300px"
              style={{ border: 0 }}
            />
          )}
        </MyBox>
      </FormControl>
      <HStack mt={4} spacing={4}>
        <Button colorScheme="primary" onClick={handleSubmit}  isLoading={isLoading} // Set button loading state
          loadingText="Updating">
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </HStack>
    </MyBox>
  );
};

export default Edit;
