import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import MyBox from "../../layout/MyBox";
import { addOffice } from "../../api/office"; // Import the API function to add a new office

const Add = ({ onClose, fetchAddresses}) => {
  const [iframeLink, setIframeLink] = useState("");
  const [location, setLocation] = useState("");
  const [title, setTitle] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isValidIframe, setIsValidIframe] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const handleSubmit = async () => {
    // Perform validation here
    if (!title || !iframeLink || !location || !contactNumber || !isValidIframe) {
      toast({
        title: "Please fill in all fields and provide a valid iframe link",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setIsLoading(true);

      // Send a POST request to the API endpoint to add a new office
      await addOffice(
        {
          iframeLink,
          title,
          location,
          contactNumber
        },
      );
      toast({
        title: "Office address added successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      fetchAddresses();
      setIsLoading(false);

      onClose();
    } catch (error) {
      console.error("Error adding office address:", error);
      toast({
        title: "Error",
        description: "Failed to add office address. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      fetchAddresses();
      setIsLoading(false);

    }
  };

  const handleIframeChange = (e) => {
    const url = e.target.value;
    setIframeLink(url);
  };

  return (
    <MyBox p={4} borderRadius="md" boxShadow="md">
      <FormControl mb={4}>
        <FormLabel>Enter iframe link</FormLabel>
        <Input
          placeholder="Enter iframe link"
          value={iframeLink}
          onChange={handleIframeChange}
        />
        {!isValidIframe && (
          <Text color="red.500" fontSize="sm" mt={1}>
            Please provide a valid iframe link
          </Text>
        )}
      </FormControl>
      {isValidIframe && (
        <MyBox p={2} borderRadius="md" boxShadow="md" mb={4}>
          <iframe
            title="Google Map"
            srcDoc={`<iframe src="${iframeLink}"></iframe>`}
            width="100%"
            height="300px"
            style={{ border: 0 }}
          />
        </MyBox>
      )}
      <FormControl mb={4}>
        <FormLabel>Location</FormLabel>
        <Textarea
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Title</FormLabel>
        <Input
          placeholder="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Contact Number</FormLabel>
        <Input
          placeholder="Contact Number"
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
        />
      </FormControl>
      <HStack spacing={4}>
        <Button colorScheme="primary" onClick={handleSubmit} isLoading={isLoading} // Set button loading state
          loadingText="Adding" >
          Add
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </HStack>
    </MyBox>
  );
};

export default Add;
