import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
} from "@chakra-ui/react";
import MyBox from "../../../layout/MyBox";
import { BASE_URL } from "../../../api/constants";
import { editCategory } from "../../../api/category";
import { useToast } from "@chakra-ui/react";

const Edit = ({ selectedItem, onClose, fetchData }) => {
  const [image, setImage] = useState(selectedItem?.img || null);
  const [name, setName] = useState(selectedItem?.name || "");
  const [previewImage, setPreviewImage] = useState(selectedItem?.img || null);
  const [isLoading, setIsLoading] = useState(false); // Loading state for the button
  const fileInputRef = useRef();
  const toast = useToast(); // Toast instance

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setImage(selectedImage);
      setPreviewImage(URL.createObjectURL(selectedImage));
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true); // Set loading state to true while request is in progress
      const categoryData = {
        id: selectedItem.id, // Assuming selectedItem contains the category ID
        image: image,
        name: name,
      };
      const response = await editCategory(categoryData);
      
      fetchData();
      toast({
        title: "Category updated.",
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error("Error editing category:", error);
      toast({
        title: "An error occurred.",
        description: "Failed to update category.",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Reset loading state regardless of success or failure
    }
  };

  return (
    <Box>
      <FormControl isRequired>
        <MyBox>
          <FormLabel>Upload Image</FormLabel>
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            display="none"
          />
          <Image
            src={
              image
                ? URL.createObjectURL(image)
                : `${BASE_URL}/api/parent-category/image/${selectedItem.id}`
            }
            alt="Placeholder"
            boxSize="200px"
            objectFit="cover"
            cursor="pointer"
            onClick={handleImageClick}
          />
        </MyBox>
      </FormControl>
      <FormControl mt={4} isRequired>
        <MyBox>
          <FormLabel>Title</FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </MyBox>
      </FormControl>
      <Button
        mt={4}
        colorScheme="primary"
        onClick={handleSubmit}
        isLoading={isLoading} // Set button loading state
        loadingText="Updating..."
      >
        Update
      </Button>
    </Box>
  );
};

export default Edit;
