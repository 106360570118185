import React, { useState, useEffect } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { deleteCategory } from "../api/category";
import { deleteSubCategory } from "../api/subcategories";

function DeleteAlert({
  isOpen,
  onClose,
  HeaderText,
  BodyText,
  data,
  fetchData,
  onConfirmDelete // Assuming fetchData is a function to refetch data after deletion
}) {
  const [btnLoading, setBtnLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (isOpen) {
      setBtnLoading(false);
    }
  }, [isOpen]);

  const handleDelete = async () => {
    try {
      setBtnLoading(true);
      if (onConfirmDelete) {
        onConfirmDelete();
        toast({
          title: "Deleted Sucessfully",
          status: "success",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      } else {
        if (data.parent_id) {
          // If parent_id exists, it's a subcategory, so call deleteSubCategory
          await deleteSubCategory(data.id);
          fetchData();
        } else if (onConfirmDelete) {
          onConfirmDelete();

        } else {
          // If parent_id doesn't exist, it's a category, so call deleteCategory
          await deleteCategory(data.id);
          fetchData();
        }

        onClose();

        toast({
          title: "Category Deleted",
          status: "success",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      }

    } catch (error) {
      console.error("Error deleting category:", error);
      toast({
        title: "Error",
        description: "Failed to delete category. Please try again.",
        position: "top-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      // Handle error, show error message, etc.
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={undefined}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {HeaderText}
          </AlertDialogHeader>

          <AlertDialogBody>{BodyText}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} isDisabled={btnLoading}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              ml={3}
              isLoading={btnLoading}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default DeleteAlert;
