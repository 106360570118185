import { Box, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const MyBox = ({ children, ...rest }) => {
  const bgColor = useColorModeValue("gray.100", "gray.600");

  return (
    <Box bg={bgColor} py={4} px={2} borderRadius="md" {...rest}>
      {children}
    </Box>
  );
};

export default MyBox;
