import React, { useState, useEffect } from "react";
import Page from "../../layout/Page";
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { HiDotsHorizontal } from "react-icons/hi";
import { BiSearchAlt, BiShow } from "react-icons/bi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import DeleteAlert from "../../components/DeleteAlert";
import Drawers from "./Drawers";
import { deleteBlogs, getAllBlogs } from "../../api/blog";
import { formatDate } from "../../components/helper";
import { BASE_URL } from "../../api/constants";
import LoaderMini from "../../components/LoaderMini";

const Blog = ({ sideBarWidth }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDrawerType, setSelectedDrawerType] = useState("");
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [articles, setArticles] = useState([]);
  const bgColor = useColorModeValue("white", "gray.700");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await getAllBlogs();
      setArticles(response);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching articles:", error);
      setIsLoading(false);
      // Handle error
    }
  };

  const filteredItems = articles.filter(
    (item) =>
      item.title?.toLowerCase().includes(searchTerm) ||
      item.slug?.toLowerCase().includes(searchTerm) ||
      item.descriptionPrev?.toLowerCase().includes(searchTerm)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value?.toLowerCase();
    setSearchTerm(searchText);
  };

  const openDrawer = (drawerType, itemData) => {
    setSelectedDrawerType(drawerType);
    setSelectedItemData(itemData);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedDrawerType("");
    setSelectedItemData(null);
  };

  const handleDeleteClick = (item) => {
    setSelectedItemId(item);
    setIsDeleteAlertOpen(true);
  };

  const confirmDeleteArticle = async (articleId) => {
    try {
      setIsDeleteAlertOpen(false);
      fetchArticles(); // Fetch articles again after deletion
    } catch (error) {
      console.error("Error deleting article:", error);
      // Handle error
    }
  };

  return (
    <Page sideBarWidth={sideBarWidth}>
      <Box mb={2}>
        <Heading>Blog Management</Heading>
      </Box>
      <Flex align="center" mb={4} justify="space-between">
        <Flex align="center" w="50%">
          <InputGroup w="100%" size={"sm"}>
            <InputLeftElement
              pointerEvents="none"
              color="gray.400"
              fontSize="1.2em"
              ml={2}
            >
              <BiSearchAlt />
            </InputLeftElement>
            <Input
              placeholder="Search by name or slug"
              value={searchTerm}
              onChange={handleSearchChange}
              borderRadius="0.3rem"
              py={2}
              pl={10}
              pr={3}
              fontSize="md"
              mr={4}
              _placeholder={{ color: "gray.400" }}
            />
          </InputGroup>
        </Flex>
        <Flex align="center">
          <Button
            variant="solid"
            colorScheme="primary"
            size="sm"
            onClick={() => openDrawer("addNew")}
          >
            New Blog
          </Button>
        </Flex>
      </Flex>
      {isLoading ? (
        <LoaderMini />
      ) : (
        <Stack>
          {currentItems.map((item, index) => (
            <Flex
              px={4}
              py={6}
              borderRadius="md"
              shadow="lg"
              bg={bgColor}
              justify="space-between"
              key={index}
              direction={{ base: "column", md: "row" }}
            >
              <Flex
                align="start"
                direction={{ base: "column", md: "row" }}
                gap={2}
              >
                <Img
                  src={`${BASE_URL}/api/article/image/${item.articleId}`}
                  boxSize="6rem"
                />
                <Box maxW="80%">
                  <Text fontWeight="semibold">{item.title}</Text>
                  <Text fontWeight="light" my={2} fontStyle="italic">
                    {item.descriptionPrev}
                  </Text>
                  <Text
                    fontWeight="light"
                    fontSize="sm"
                    my={2}
                    fontStyle="italic"
                  >
                    {item.username}
                  </Text>
                </Box>
              </Flex>
              <Flex direction="column" justify="space-between">
                <Text fontWeight="semibold" fontSize="sm">
                  {formatDate(item.date)}
                </Text>
                <Flex justify="end" align="end">
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<HiDotsHorizontal />}
                      variant="ghost"
                      size="sm"
                    />
                    <MenuList>
                      <MenuItem
                        icon={<BiShow />}
                        onClick={() => openDrawer("show", item)}
                      >
                        Show
                      </MenuItem>
                      <MenuItem
                        icon={<FiEdit />}
                        onClick={() => openDrawer("edit", item)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        icon={<FiTrash2 />}
                        onClick={() => handleDeleteClick(item)}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Stack>
      )}
      <Drawers
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        drawerType={selectedDrawerType}
        data={selectedItemData}
        fetchArticles={fetchArticles}
      />
      <DeleteAlert
        isOpen={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        onConfirmDelete={() => confirmDeleteArticle(selectedItemId.articleId)}
        HeaderText={"Delete Blog"}
        BodyText={`Are you sure you want to delete ${selectedItemId?.title}?`}
      />
    </Page>
  );
};

export default Blog;
