import axios from "axios";
import FormData from "form-data";
import { BASE_URL } from "./constants";
export const getToken = () => localStorage.getItem("token");


export const addOffice = async (officeData) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.post(
            `${BASE_URL}/api/office`,
            officeData,
            {
                headers: {
                    'token': token,
                },
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const getAllOffices = async () => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {

        const response = await axios.get(
            `${BASE_URL}/api/office/`, {
            headers: {
                'token': `${token}`,
            },
        }
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const deleteOffice = async (id) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {

        const response = await axios.delete(
            `${BASE_URL}/api/office/${id}`, {
            headers: {
                'token': `${token}`,
            },
        }
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const updateOffice = async (id, officeData) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {

        const response = await axios.put(
            `${BASE_URL}/api/office/${id}`, officeData, {
            headers: {
                'token': `${token}`,
            },
        }
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};
