import axios from "axios";
import FormData from "form-data";
import { BASE_URL } from "./constants";
export const getToken = () => localStorage.getItem("token");


export const addBlog = async (blogData) => {

    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const formData = new FormData();
        formData.append("image", blogData.image); // Assuming blogData.image is the file
        formData.append("title", blogData.title);
        formData.append("descriptionPrev", blogData.descriptionPrev);
        formData.append("htmlBody", JSON.stringify(blogData.htmlBody));
        formData.append("token", token);
        formData.append("slug", blogData.slug.replace(/\s+/g, "-"));

        const response = await axios.post(
            `${BASE_URL}/api/article`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`,
                },
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const editBlog = async (blogData) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const formData = new FormData();
        formData.append("image", blogData.image); // Assuming blogData.image is the file
        formData.append("title", blogData.title);
        formData.append("descriptionPrev", blogData.descriptionPrev);
        formData.append("htmlBody", JSON.stringify(blogData.htmlBody));
        formData.append("token", token);
        formData.append("slug", blogData.slug.replace(/\s+/g, "-"));

        const response = await axios.put(
            `${BASE_URL}/api/article/${blogData.id}?${token}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`,
                },
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const getAllBlogs = async () => {

    try {

        const response = await axios.get(
            `${BASE_URL}/api/article`,
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};

export const getArticlesHtmlBody = async (id) => {
    try {

        const response = await axios.get(
            `${BASE_URL}/api/article/htmlBody/${id}`,
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const deleteBlogs = async (blogId) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.delete(
            `${BASE_URL}/api/article/${blogId}?token=${token}`
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};