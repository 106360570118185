import { Box, Img, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MyBox from "../../layout/MyBox";
import ReactQuill from "react-quill";
import { BASE_URL } from "../../api/constants";
import { formatDate } from "../../components/helper";
import { getArticlesHtmlBody } from "../../api/blog";
import LoaderMini from "../../components/LoaderMini";

const View = ({ selectedItem, onClose }) => {
  const [image, setImage] = useState(selectedItem?.img || null);
  const [date, setDate] = useState(selectedItem?.date || "");
  const [title, setTitle] = useState(selectedItem?.title || "");
  const [description, setDescription] = useState(
    selectedItem?.descriptionPrev || ""
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getBody = async () => {
      try {
        const result = await getArticlesHtmlBody(selectedItem.articleId); 
        setBody(result.content);
      } catch (error) {
        console.error("Error fetching sections:", error);
      } finally {
        setLoading(false);
      }
    };
    getBody();
  }, [selectedItem]);

  const [slug, setSlug] = useState(selectedItem?.slug || "");
  const [body, setBody] = useState(selectedItem?.body || "");
  const [previewImage, setPreviewImage] = useState(selectedItem?.img || null);
  if (loading) {
    return <LoaderMini/>;
  }
  
  return (
    <Box>
      <Stack spacing={4}>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={2}>
          <MyBox>
            <Text fontWeight="semibold">Image</Text>
            <Img
              src={`${BASE_URL}/api/article/image/${selectedItem.articleId}`}
              alt="Placeholder"
              boxSize="200px"
              objectFit="cover"
            />
          </MyBox>
          <Box>
            <MyBox mb={2}>
              <Text fontWeight="semibold">Publised Date:</Text>
              <Text>{formatDate(date)}</Text>
            </MyBox>
            <MyBox mb={2}>
              <Text fontWeight="semibold">Title</Text>
              <Text>{title}</Text>
            </MyBox>
            <MyBox>
              <Text fontWeight="semibold">Slug</Text>
              <Text>{slug}</Text>
            </MyBox>
          </Box>
        </SimpleGrid>
        <MyBox>
          <Text fontWeight="semibold">Description</Text>
          <Text>{description}</Text>
        </MyBox>
        <MyBox>
          <Text fontWeight="semibold">Body</Text>
          <ReactQuill value={body} readOnly={true} theme={"bubble"} />
        </MyBox>
      </Stack>
    </Box>
  );
};

export default View;
