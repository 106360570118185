import {
  Box,
  Button,
  Flex,
  FormLabel,
  Img,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useToast,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import MyBox from "../../layout/MyBox";
import ReactQuill from "react-quill";
import CustomBlog from "../../components/CustomBlog";
import { BASE_URL } from "../../api/constants";
import { formatDate } from "../../components/helper";
import { editBlog, getArticlesHtmlBody } from "../../api/blog";

const Edit = ({ selectedItem, onSave, onClose, fetchArticles }) => {
  const [image, setImage] = useState(selectedItem?.img || null);
  const [articlesHtmlBody, setArticlesHtmlBody] = useState(null);
  const [date, setDate] = useState(selectedItem?.date ? new Date(selectedItem.date).toISOString().split('T')[0] : '');
  const [btnLoading, setBtnLoading] = useState(false);
  const [title, setTitle] = useState(selectedItem?.title || "");
  const [description, setDescription] = useState(
    selectedItem?.descriptionPrev || ""
  );
  const [slug, setSlug] = useState(selectedItem?.slug || "");
  const [body, setBody] = useState(selectedItem?.body || "");
  const [previewImage, setPreviewImage] = useState(selectedItem?.image || null);
  const fileInputRef = useRef();

  const toast = useToast();

  useEffect(() => {
    fetchArticlesHtmlBody();
  }, []);

  const handleSaveBlog = async (blogPost) => {
    setBtnLoading(true);
    try {
      // Call the addBlog function with the new blog data
      await editBlog({
        image: image,
        title,
        descriptionPrev: description,
        htmlBody: blogPost,
        slug,
        id: selectedItem.articleId,
      });
      toast({
        title: "Blog Added",
        description: "Blog has been successfully added.",
        status: "success",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });

      fetchArticles();
      onClose();
    
    } catch (error) {
      console.error("Error adding blog:", error);
      toast({
        title: "Error Adding Blog",
        description: "Please try again",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      // onClose();
      setBtnLoading(false);

      // Handle error
    }
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setImage(selectedImage);
      setPreviewImage(URL.createObjectURL(selectedImage));
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const fetchArticlesHtmlBody = async () => {
    try {
      const response = await getArticlesHtmlBody(selectedItem.articleId);
      setArticlesHtmlBody(response);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  return (
    <Box>
      <Stack spacing={4}>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={2}>
          <MyBox>
            <FormLabel>Upload Image</FormLabel>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              display="none"
            />
            <Image
              src={
                image
                  ? URL.createObjectURL(image)
                  : `${BASE_URL}/api/article/image/${selectedItem?.articleId}`
              }
              alt="Placeholder"
              boxSize="200px"
              objectFit="cover"
              cursor="pointer"
              onClick={handleImageClick}
            />
          </MyBox>
          <Box>
            {/* <MyBox mb={2}>
              <Text fontWeight="semibold">Published Date:</Text>
              <Input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </MyBox> */}
            <MyBox mb={2}>
              <Text fontWeight="semibold">Slug</Text>
              <Input
                type="text"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
              />
            </MyBox>
            <MyBox>
              <Text fontWeight="semibold">Description</Text>
              <Input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </MyBox>
          </Box>
        </SimpleGrid>

        <MyBox>
          <Text fontWeight="semibold">Body</Text>
          {/* Render CustomBlog with body content */}
          <CustomBlog
            fields={{ showAuthor: true, showCreationDate: true }}
            date={date}
            articlesHtmlBody={articlesHtmlBody}
            onSave={handleSaveBlog} // Handle changes in the body content
            btnLoading={btnLoading}
          />
        </MyBox>
      </Stack>
      {/* Button to submit the edited item */}
    </Box>
  );
};

export default Edit;
