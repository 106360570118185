import axios from "axios";
import FormData from "form-data";
import { BASE_URL } from "./constants";

export const getToken = () => localStorage.getItem("token");




export const addSubCategory = async (subCategoryData) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const formData = new FormData();
        formData.append("image", subCategoryData.image); // Assuming subCategoryData.image is the file
        formData.append("name", subCategoryData.name);
        formData.append("description", subCategoryData.description);
        formData.append("parent_id", subCategoryData.parent_id);
        formData.append("slug", subCategoryData.slug);


        const response = await axios.post(
            `${BASE_URL}/api/child-category/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`,
                },
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const editSubCategory = async (subCategoryData) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const formData = new FormData();
        formData.append("image", subCategoryData.image); // Assuming subCategoryData.image is the file
        formData.append("name", subCategoryData.name);
        formData.append("description", subCategoryData.description);
        formData.append("parent_id", subCategoryData.parent_id);
        formData.append("slug", subCategoryData.slug);

        const response = await axios.put(
            `${BASE_URL}/api/child-category/${subCategoryData.id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`,
                },
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};



export const getAllSubCategory = async () => {
    try {

        const response = await axios.get(
            `${BASE_URL}/api/child-category/`,
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const deleteSubCategory = async (categoryId) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.delete(
            `${BASE_URL}/api/child-category/${categoryId}?token=${token}`
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};
