import React from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import MyBox from "../../layout/MyBox";

const View = ({ selectedItem, onClose }) => {
  
  return (
    <MyBox p={4} borderRadius="md" boxShadow="md">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="md"
        overflow="hidden"
        boxShadow="md"
        mb={4}
      >
        <Box
          as="iframe"
          title="Google Map"
          srcDoc={`<iframe src="${selectedItem.iframeLink}"></iframe>`}
          width="100%"
          height="300px"
          style={{ border: 0 }}
        />
      </Box>

      <VStack spacing={4} align="start">
        <FormControl>
          <FormLabel fontWeight="semibold">Title:</FormLabel>
          <Text>{selectedItem.title}</Text>
        </FormControl>
        <FormControl>
          <FormLabel fontWeight="semibold">Location:</FormLabel>
          <Text>{selectedItem.location}</Text>
        </FormControl>
        <FormControl>
          <FormLabel fontWeight="semibold">Contact Number:</FormLabel>
          <Text>{selectedItem.contactNumber}</Text>
        </FormControl>
        <Button colorScheme="primary" onClick={onClose}>
          Close
        </Button>
      </VStack>
    </MyBox>
  );
};

export default View;
