import axios from "axios";
import FormData from "form-data";
import { BASE_URL } from "./constants";
import { refreshToken, tokenValidity } from "./auth";

export const getToken = () => localStorage.getItem("token");





export const getAllUsers = async () => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.get(
            `${BASE_URL}/api/user/`, {
            headers: {
                'token': `${token}`,
            },
        }
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (id) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }

    try {

        const response = await axios.delete(
            `${BASE_URL}/api/user/${id}`, {
            headers: {
                'token': `${token}`,
            },
        }
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const addUser = async (formData) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {

        const response = await axios.post(
            `${BASE_URL}/api/user/signup`, formData, {
            headers: {
                'token': `${token}`,
            },
        }
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const updateUser = async (id, formData) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {

        const response = await axios.put(
            `${BASE_URL}/api/user/${id}`, formData, {
            headers: {
                'token': `${token}`,
            },
        }
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};
