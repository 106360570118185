import React, { useEffect, useState } from "react";
import Page from "../../../layout/Page";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { HiDotsHorizontal } from "react-icons/hi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { BiChevronLeft, BiChevronRight, BiSearchAlt } from "react-icons/bi";
import Drawers from "./Drawers";
import DeleteAlert from "../../../components/DeleteAlert";
import { getAllCategory } from "../../../api/category";
import { BASE_URL } from "../../../api/constants";
import LoaderMini from "../../../components/LoaderMini";

const ParentCategory = ({ sideBarWidth }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDrawerType, setSelectedDrawerType] = useState("");
  const [selectedItemData, setSelectedItemData] = useState(null);
  const bgColor = useColorModeValue("white", "gray.700");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const fetchData = async () => {
    try {
      const data = await getAllCategory();
      setCategoryData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching category data:", error);
      // Handle error, show error message, etc.
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (categoryData.length === 0) {
      fetchData();
    }
  }, []);

  const filteredItems = categoryData.filter((item) =>
    item.name?.toLowerCase().includes(searchTerm)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleSearchChange = (event) => {
    const searchText = event.target.value?.toLowerCase();
    setSearchTerm(searchText);
  };
  const openDrawer = (drawerType, itemData) => {
    setSelectedDrawerType(drawerType);
    setSelectedItemData(itemData);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedDrawerType("");
    setSelectedItemData(null);
  };

  const handleDeleteClick = (item) => {
    setSelectedItemId(item.name);
    setSelectedItemData(item);
    setIsDeleteAlertOpen(true);
  };
  if (isLoading) {
    return <LoaderMini />;
  }
  return (
    <Page sideBarWidth={sideBarWidth}>
      <Heading mb={3}>Parent Categories</Heading>
      <Flex align="center" mb={4} justify="space-between">
        <Flex align="center" w="50%">
          <InputGroup w="100%" size={"sm"}>
            <InputLeftElement
              pointerEvents="none"
              color="gray.400"
              fontSize="1.2em"
              ml={2}
            >
              <BiSearchAlt />
            </InputLeftElement>
            <Input
              placeholder="Search by name"
              value={searchTerm}
              onChange={handleSearchChange}
              borderRadius="0.3rem"
              py={2}
              pl={10}
              pr={3}
              fontSize="md"
              mr={4}
              _placeholder={{ color: "gray.400" }}
            />
          </InputGroup>
        </Flex>
        <Flex align="center">
          <Button
            variant="solid"
            colorScheme="primary"
            size="sm"
            onClick={() => openDrawer("addNew")}
          >
            New Category
          </Button>
        </Flex>
      </Flex>
      <Flex
        wrap="wrap"
        gap={2}
        my={4}
        justify={{ base: "center", md: "start" }}
      >
        {currentItems.map((item, index) => (
          <Box
            bg={bgColor}
            px={4}
            pb={6}
            borderRadius="lg"
            shadow="xl"
            key={index}
          >
            <Flex justify="end">
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<HiDotsHorizontal />}
                  variant="ghost"
                  size="sm"
                />
                <MenuList>
                  <MenuItem
                    icon={<FiEdit />}
                    onClick={() => openDrawer("edit", item)}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    icon={<FiTrash2 />}
                    onClick={() => handleDeleteClick(item)}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
            <Stack textAlign="start">
              <Img
                src={`${BASE_URL}/api/parent-category/image/${item.id}}`}
                boxSize="9rem"
                objectFit="cover"
                borderRadius="md"
              />
              <Text fontWeight="semibold">{item.name}</Text>
            </Stack>
          </Box>
        ))}
      </Flex>
      <Flex justify="space-between" mt={4} align="center">
        <Box>
          <IconButton
            icon={<BiChevronLeft />}
            isDisabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            aria-label="Previous Page"
          />
          <IconButton
            icon={<BiChevronRight />}
            isDisabled={indexOfLastItem >= filteredItems.length}
            onClick={() => handlePageChange(currentPage + 1)}
            ml={2}
            aria-label="Next Page"
          />
        </Box>
        <Text fontSize="smaller">
          Page {currentPage} of {Math.ceil(filteredItems.length / itemsPerPage)}
        </Text>
      </Flex>
      <Drawers
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        drawerType={selectedDrawerType}
        data={selectedItemData}
        fetchData={fetchData}
      />
      <DeleteAlert
        isOpen={isDeleteAlertOpen}
        onClose={() => setIsDeleteAlertOpen(false)}
        // onConfirmDelete={handleConfirmDelete}
        HeaderText={"Delete Category"}
        BodyText={`Are you sure you want to delete ${selectedItemId}?`}
        data={selectedItemData}
        fetchData={fetchData}
      />
    </Page>
  );
};

export default ParentCategory;
