//Dev BASE_URL
export const BASE_URL = 'https://nextbackend.fourseasonglassrooms.com'
export const role = "admin"
export const Name = "FourSeason"
export const branchId = localStorage.getItem("branchId");
export const branchName = localStorage.getItem("branchName");
export const token = localStorage.getItem("token");

export const getToken = () => {
    return localStorage.getItem('token');
};
