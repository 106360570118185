import { Box, Container, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const Page = ({ sideBarWidth, children, ...rest }) => {
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const bgColorChild = useColorModeValue("gray.200", "gray.600");
  return (
    <Box bg={bgColor} py={8} w="auto" minH="100vh">
      <Container maxW="container.xxl" justifySelf="center">
        <Box
          py={4}
          px={2}
          borderRadius="md"
          ml={{ base: 0, lg: sideBarWidth === "small" ? 14 : 60 }}
          transition="margin 0.3s ease-in-out"
          bg={bgColorChild}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default Page;
