import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  useToast,
  Switch,
  IconButton,
} from "@chakra-ui/react";
import MyBox from "../../../layout/MyBox";
import { getAllCategory } from "../../../api/category";
import { addSubCategory } from "../../../api/subcategories";
import { PiPlus } from "react-icons/pi";
import { MdBackHand, MdDelete } from "react-icons/md";
import { addChildCategoryGallery, addChildCategorySection } from "../../../api/childSection";
import Page from "../../../layout/Page";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";

const AddNew = ({ onClose, fetchSubCategories, sideBarWidth }) => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [sectionPreviewImages, setSectionPreviewImages] = useState({});
  const [galleryPreviewImages, setGalleryPreviewImages] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef();
  const sectionFileInputRefs = useRef([]);
  const galleryFileInputRefs = useRef([]);

  const [gallery, setGallery] = useState([
    {
      img: null,
      description: "",
    },
  ]);
  const [sections, setSections] = useState([
    {
      imageOrVideo: null,
      title: "",
      description: "",
      isImageUpload: true,
      videoLink: "",
    },
  ]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleSectionImageClick = (index) => {
    sectionFileInputRefs.current[index].click();
  };
  const handleGalleryImageClick = (index) => {
    galleryFileInputRefs.current[index].click();
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setImage(selectedImage);
      setPreviewImage(URL.createObjectURL(selectedImage));
    }
  };

  const handleCategorySelect = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleFileChangeInSection = (event, index) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const updatedSections = [...sections];
      updatedSections[index] = {
        ...updatedSections[index],
        imageOrVideo: selectedFile,
        isImageUpload: true,
      };
      setSectionPreviewImages((prevState) => ({
        ...prevState,
        [index]: URL.createObjectURL(selectedFile),
      }));
      setSections(updatedSections);
    }
  };
  const handleFileChangeInGallery = (event, index) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const updatedGallery = [...gallery];
      updatedGallery[index] = {
        ...updatedGallery[index],
        img: selectedFile,
      };
      setGalleryPreviewImages((prevState) => ({
        ...prevState,
        [index]: URL.createObjectURL(selectedFile),
      }));
      setGallery(updatedGallery);
    }
  };

  const toast = useToast();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const subCategoryData = {
        image: image,
        parent_id: selectedCategory,
        name: title,
        description: description,
        slug: slug.replace(/\s+/g, "-"), // Replace spaces with dashes
      };

      const response = await addSubCategory(subCategoryData);

      if (sections && sections.length > 0) {
        const promises = [];
        sections.forEach((section, index) => {
          const formData = new FormData();
          if (section.isImageUpload && section.imageOrVideo) {
            formData.append(`image`, section.imageOrVideo);
          } else {
            formData.append(`videoLink`, section.imageOrVideo); // Assuming you have a field named `video` in your API
          }
          formData.append(`title`, section.title);
          formData.append(`description`, section.description);
          formData.append(`childCategoryId`, response.id);

          const promise = addChildCategorySection(formData);
          promises.push(promise);
        });

        try {
          const responses = await Promise.all(promises);



        } catch (error) {
          console.error("Error:", error);
        }
      }

      if (gallery && gallery.length > 0) {
        const promises = [];
        gallery.forEach((galleries, index) => {
          const formData = new FormData();
          formData.append(`image`, galleries.img);
          formData.append(`description`, galleries.description);
          formData.append(`childCategorySectionId`, response.id);

          const promise = addChildCategoryGallery(formData);
          promises.push(promise);
        });

        try {
          const responses = await Promise.all(promises);



        } catch (error) {
          console.error("Error:", error);
        }
      }

      toast({
        title: "Subcategory added.",
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchSubCategories();
    } catch (error) {
      console.error("Error adding subcategory:", error);
      toast({
        title: "Error",
        description: "Failed to add subcategory.",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddSection = () => {
    const newSection = {
      imageOrVideo: null,
      title: "",
      description: "",
      isImageUpload: true,
      videoLink: "",
    };
    setSections([...sections, newSection]);
  };
  const handleAddGallery = () => {
    const newGallery = {
      img: null,
      description: "",
    };
    setGallery([...gallery, newGallery]);
  };

  const handleRemoveSection = (indexToRemove) => {
    const updatedSections = sections.filter(
      (_, index) => index !== indexToRemove
    );
    setSections(updatedSections);
  };
  const handleRemoveGallery = (indexToRemove) => {
    const updatedGallery = gallery.filter(
      (_, index) => index !== indexToRemove
    );
    setGallery(updatedGallery);
  };

  const handleSwitchChange = (index) => {
    const updatedSections = [...sections];
    const currentSection = updatedSections[index];

    if (currentSection.isImageUpload) {
      currentSection.videoLink = "";
    } else {
      currentSection.imageOrVideo = null;
      setSectionPreviewImages((prevState) => {
        const newState = { ...prevState };
        delete newState[index];
        return newState;
      });
    }

    currentSection.isImageUpload = !currentSection.isImageUpload;

    setSections(updatedSections);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getAllCategory();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <Page sideBarWidth={sideBarWidth}>
      <Button
        as={Link}
        to={-1}
        leftIcon={<IoMdArrowBack />}
        my={2}
        colorScheme="primary"
        size="sm"

      >
        Go Back
      </Button>
      <Flex gap={4} wrap="wrap">
        <MyBox>
          <FormControl isRequired>
            <FormLabel>Upload Image</FormLabel>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              display="none"
            />
            <Image
              src={
                previewImage || "https://placehold.co/600x400?text=Upload Image"
              }
              alt="Placeholder"
              boxSize="200px"
              objectFit="cover"
              cursor="pointer"
              onClick={handleImageClick}
            />
          </FormControl>
        </MyBox>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={4} w="100%">
          <MyBox>
            <FormControl mt={4} isRequired>
              <FormLabel>Parent Category</FormLabel>
              <Select
                placeholder="Select parent category"
                value={selectedCategory}
                onChange={handleCategorySelect}
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </MyBox>

          <MyBox>
            <FormControl mt={4} isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
          </MyBox>
          <MyBox>
            <FormControl mt={4} isRequired>
              <FormLabel>Slug</FormLabel>
              <Input
                type="text"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
              />
            </FormControl>
          </MyBox>
        </SimpleGrid>
        <MyBox w="100%">
          <FormControl isRequired>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
        </MyBox>
      </Flex>
      <FormControl mt={2}>
        <MyBox>
          <FormLabel>Upload Gallery Images</FormLabel>
          {gallery.map((item, index) => (
            <Box key={index} p={4} rounded="lg" shadow="md" mb={4} flex={1}>
              <Flex align="center" justify="center" gap={1}>
                <Box>
                  <FormControl isRequired>
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileChangeInGallery(e, index)}
                      ref={(el) => (galleryFileInputRefs.current[index] = el)}
                      display="none"
                    />
                    <Image
                      src={
                        galleryPreviewImages[index] ||
                        "https://placehold.co/600x400?text=Upload Image"
                      }
                      alt="Placeholder"
                      boxSize="100px"
                      objectFit="cover"
                      cursor="pointer"
                      onClick={() => handleGalleryImageClick(index)}
                    />
                  </FormControl>
                </Box>
                <FormControl mt={2} isRequired>
                  <Textarea
                    placeholder="Description"
                    value={gallery.description}
                    onChange={(e) => {
                      const updatedGallery = [...gallery];
                      updatedGallery[index].description = e.target.value;
                      setGallery(updatedGallery);
                    }}
                  />
                </FormControl>
              </Flex>
              <Button
                colorScheme="red"
                size="sm"
                onClick={() => handleRemoveGallery(index)}
                leftIcon={<MdDelete />}
                variant="ghost"
              >
                Remove
              </Button>
            </Box>
          ))}
          <Flex justify="center">
            <Button
              onClick={handleAddGallery}
              variant="outline"
              size="sm"
              color="green"
              leftIcon={<PiPlus />}
            >
              Add Images
            </Button>
          </Flex>
        </MyBox>
      </FormControl>
      <Divider border="1px solid gray" my={4} />
      {sections.map((section, index) => (
        <MyBox key={index} p={4} rounded="lg" shadow="md" mb={4}>
          <Flex justify="space-between" align="center" mb={2}>
            <Text fontSize="lg" fontWeight="bold">
              Section {index + 1}
            </Text>
            <Button
              colorScheme="red"
              size="sm"
              onClick={() => handleRemoveSection(index)}
              leftIcon={<MdDelete />}
              variant="ghost"
            >
              Remove
            </Button>
          </Flex>
          <FormControl display="flex" alignItems="center" mb={2}>
            <FormLabel htmlFor={`imageOrVideoSwitch${index}`} mb="0" mr={2}>
              {section.isImageUpload ? "Upload Image" : "Enter Video URL"}
            </FormLabel>
            <Switch
              id={`imageOrVideoSwitch${index}`}
              isChecked={section.isImageUpload}
              onChange={() => handleSwitchChange(index)}
              colorScheme="green"
              size="sm"
            />
          </FormControl>

          <Divider />
          <FormControl mt={2} isRequired>
            {section.isImageUpload ? (
              <FormControl isRequired>
                <FormLabel>Upload Image</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChangeInSection(e, index)}
                  ref={(el) => (sectionFileInputRefs.current[index] = el)}
                  display="none"
                />
                <Image
                  src={
                    sectionPreviewImages[index] ||
                    "https://placehold.co/600x400?text=Upload Image"
                  }
                  alt="Placeholder"
                  boxSize="200px"
                  objectFit="cover"
                  cursor="pointer"
                  onClick={() => handleSectionImageClick(index)}
                />
              </FormControl>
            ) : (
              <Input
                type="text"
                placeholder="Enter video URL..."
                value={section.videoLink}
                onChange={(e) => {
                  const updatedSections = [...sections];
                  updatedSections[index].videoLink = e.target.value;
                  setSections(updatedSections);
                }}
              />
            )}
          </FormControl>
          <FormControl mt={2} isRequired>
            <Input
              type="text"
              placeholder="Title"
              value={section.title}
              onChange={(e) => {
                const updatedSections = [...sections];
                updatedSections[index].title = e.target.value;
                setSections(updatedSections);
              }}
            />
          </FormControl>
          <FormControl mt={2} isRequired>
            <Textarea
              placeholder="Description"
              value={section.description}
              onChange={(e) => {
                const updatedSections = [...sections];
                updatedSections[index].description = e.target.value;
                setSections(updatedSections);
              }}
            />
          </FormControl>
        </MyBox>
      ))}
      <Flex justify="center">
        <Button
          onClick={handleAddSection}
          variant="outline"
          size="sm"
          color="green"
          leftIcon={<PiPlus />}
        >
          Add Section
        </Button>
      </Flex>
      <Flex justify="end">
        <Button
          mt={4}
          colorScheme="primary"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          loadingText="Adding..."
        >
          Add
        </Button>
      </Flex>
    </Page>
  );
};

export default AddNew;
