import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import Page from "../layout/Page";

const Home = ({ sideBarWidth }) => {
  
  return (
    <Page sideBarWidth={sideBarWidth}>
      <Heading>Dashboard</Heading>
    </Page>
  );
};

export default Home;
