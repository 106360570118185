import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  useToast,
} from "@chakra-ui/react";
import MyBox from "../../../layout/MyBox";
import { addCategory } from "../../../api/category";

const AddNew = ({ onClose, fetchData }) => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state of the button
  const fileInputRef = useRef();

  const toast = useToast(); // Hook to display toast notifications

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setImage(selectedImage);
      setPreviewImage(URL.createObjectURL(selectedImage));
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true); // Set loading state to true
      const categoryData = {
        image: image,
        name: title,
        description: "test",
      };
      const response = await addCategory(categoryData);
      
      fetchData();
      onClose(); // Close modal
      toast({
        title: "Category Added",
        description: "Category has been successfully added.",
        status: "success",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      console.error("Error adding category:", error);
      toast({
        title: "Error",
        description: "An error occurred while adding the category.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Reset loading state regardless of success or failure
    }
  };

  return (
    <Box>
      <MyBox>
        <FormControl isRequired>
          <FormLabel>Upload Image</FormLabel>
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            //   style={{ display: "none" }}
            display="none"
          />
          <Image
            src={
              previewImage || "https://placehold.co/600x400?text=Upload Image"
            }
            alt="Placeholder"
            boxSize="200px"
            objectFit="cover"
            cursor="pointer"
            onClick={handleImageClick}
          />
        </FormControl>
      </MyBox>
      <MyBox my={2}>
        <FormControl mt={4} isRequired>
          <FormLabel>Title</FormLabel>
          <Input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
      </MyBox>
      <Button
        mt={4}
        colorScheme="primary"
        onClick={handleSubmit}
        isLoading={isLoading} // Set loading state of the button
        loadingText="Adding..." // Text to display while button is in loading state
      >
        Add
      </Button>
    </Box>
  );
};

export default AddNew;
