import React from "react";
import { Box, Heading, Text, FormControl, FormLabel } from "@chakra-ui/react";
import MyBox from "../../layout/MyBox";

const View = ({ selectedItem, onClose }) => {
  return (
    <MyBox p={4} borderRadius="md" boxShadow="md">
      <Heading size="md" mb={4}>User Details</Heading>
      
      <FormControl>
        <FormLabel fontWeight="semibold">Name</FormLabel>
        <Text>{selectedItem.name}</Text>
      </FormControl>
      
      <FormControl mt={4}>
        <FormLabel fontWeight="semibold">Email</FormLabel>
        <Text>{selectedItem.email}</Text>
      </FormControl>
      
      <FormControl mt={4}>
        <FormLabel fontWeight="semibold">Role</FormLabel>
        <Text>{selectedItem.role}</Text>
      </FormControl>
    </MyBox>
  );
};

export default View;
