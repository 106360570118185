import axios from "axios";
import FormData from "form-data";
import { BASE_URL } from "./constants";

export const getToken = () => localStorage.getItem("token");


export const addChildCategorySection = async (formData) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.post(
            `${BASE_URL}/api/child-category-section/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`,
                },
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};

export const addChildCategoryGallery = async (formData) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.post(
            `${BASE_URL}/api/child-category-section-image/`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`,
                },
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const getAllSections = async (id) => {
    try {

        const response = await axios.get(
            `${BASE_URL}/api/child-category-section/childCategory/${id}`,
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};

export const getAllGallery = async (id) => {
    try {

        const response = await axios.get(
            `${BASE_URL}/api/child-category-section-image/section/${id}`,
        );
        
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const editChildCategorySection = async (formData, id) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.put(
            `${BASE_URL}/api/child-category-section/${id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`,
                },
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};

export const editChildCategoryGallery = async (formData, id) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.put(
            `${BASE_URL}/api/child-category-section-image/${id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`,
                },
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const deleteChildCategorySection = async (id) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.delete(
            `${BASE_URL}/api/child-category-section/${id}?token=${token}`
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};


export const deleteChildCategoryGallery = async (id) => {
    const token = getToken(); // Retrieve token
    if (!token) {
        // Token is not defined or null, clear local storage and redirect to login page
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return; // Stop execution
    }
    try {
        const response = await axios.delete(
            `${BASE_URL}/api/child-category-section-image/${id}?token=${token}`
        );
        return response.data; // Return the response data
    } catch (error) {
        throw error;
    }
};