// CustomBlog.js

import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Heading,
  Text,
} from "@chakra-ui/react";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const CustomBlog = ({ onSave, fields, date, articlesHtmlBody, btnLoading }) => {
  const { showAuthor, showCreationDate } = fields;

  const [title, setTitle] = useState(articlesHtmlBody?.title || "");
  const [content, setContent] = useState(articlesHtmlBody?.content || "");
  const [author, setAuthor] = useState(articlesHtmlBody?.author || "");
  const [creationDate, setCreationDate] = useState(
    date ? new Date(date) : new Date()
  );
  console.log(date)
  const [preview, setPreview] = useState(false);

  const togglePreview = () => {
    setPreview(!preview);
  };

  useEffect(() => {
    // Update state if articlesHtmlBody changes
    if (articlesHtmlBody) {
      setTitle(articlesHtmlBody.title || "");
      setContent(articlesHtmlBody.content || "");
      setAuthor(articlesHtmlBody.author || "");
      setCreationDate( date ? new Date(date) : new Date());
    }
  }, [articlesHtmlBody]);

  const handleSubmit = () => {
    const blogPost = {
      title,
      content,
      author: showAuthor ? author : "",
      creationDate: showCreationDate ? creationDate.toISOString() : null,
    };
    onSave(blogPost);
  };

  return (
    <Box>
      <Flex p={4}>
        <Box flex="1" mr={8}>
          <FormControl mb={4}>
            <FormLabel htmlFor="blogTitle">Title</FormLabel>
            <Input
              type="text"
              id="blogTitle"
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel htmlFor="blogContent">Content</FormLabel>
            <ReactQuill
              theme="snow"
              value={content}
              onChange={(value) => setContent(value)}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image", "video"],
                  ["clean"],
                  ["code-block"],
                  [{ script: "sub" }, { script: "super" }],
                  [{ color: [] }, { background: [] }],
                  [{ font: [] }],
                  [{ align: [] }],
                  ["blockquote", "code-block", "image", "video"],
                  ["direction", { size: ["small", false, "large", "huge"] }],
                ],
              }}
            />
            <FormHelperText>Format your blog content</FormHelperText>
          </FormControl>

          {showAuthor && (
            <FormControl mb={4}>
              <FormLabel htmlFor="blogAuthor">Author</FormLabel>
              <Input
                type="text"
                id="blogAuthor"
                placeholder="Enter author name"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
            </FormControl>
          )}
          {showCreationDate && (
            <FormControl mb={4}>
              <FormLabel htmlFor="blogCreationDate">Creation Date</FormLabel>

              {/* <DatePicker
                onChange={(date) => setCreationDate(date)}
                value={creationDate || undefined}
                calendarAriaLabel="Toggle calendar"
                clearAriaLabel="Clear value"
                clearIcon={null}
                format="y-MM-dd"
                locale="en-US"
                maxDate={new Date()}
                minDate={new Date(2000, 0, 1)}
                nativeInputAriaLabel="Date"
                returnValue="start"
                shouldCloseCalendar={({ reason }) => reason !== "outsideAction"}
                showLeadingZeros
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
              /> */}
              <Input
                type="date"
                value={date}
                onChange={(e) => setCreationDate(e.target.value)}
              />
            </FormControl>
          )}

          <Button
            colorScheme="teal"
            variant="outline"
            mr={2}
            size="sm"
            onClick={togglePreview}
          >
            {preview ? "Hide Preview" : "Show Preview"}
          </Button>
          <Button
            colorScheme="teal"
            onClick={handleSubmit}
            type="submit"
            size="sm"
            isLoading={btnLoading}
            isDisabled={!title || !content || (showAuthor && !author) || (showCreationDate && !creationDate)}
            
          >
            Save
          </Button>
        </Box>

        {preview && (
          <Box flex="1">
            <Heading mb={4}>{title}</Heading>
            {showAuthor && (
              <Text color="gray.500" mb={2}>
           {author} | {creationDate ? creationDate.toISOString().split('T')[0] : ""}

              </Text>
            )}
            <ReactQuill value={content} readOnly={true} theme={"bubble"} />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default CustomBlog;
