import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import MyBox from "../../layout/MyBox";
import CustomBlog from "../../components/CustomBlog";
import { addBlog } from "../../api/blog";

const AddNew = ({ onClose, fetchArticles }) => {
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [body, setBody] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const fileInputRef = useRef();
  const toast = useToast();

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setImage(selectedImage);
      setPreviewImage(URL.createObjectURL(selectedImage));
    }
  };

  const handleSaveBlog = async (blogPost) => {
    setBtnLoading(true);
    try {
      // Call the addBlog function with the new blog data
      await addBlog({
        image,
        title,
        descriptionPrev: description,
        htmlBody: blogPost,
        slug,
      });
      toast({
        title: "Blog Added",
        description: "Blog has been successfully added.",
        status: "success",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      // Reset form fields
      setImage(null);
      setPreviewImage(null);
      setTitle("");
      setSlug("");
      setDescription("");
      setBody("");
      fetchArticles();
      onClose();
      setBtnLoading(false);
    } catch (error) {
      console.error("Error adding blog:", error);
      toast({
        title: "Error Adding Blog",
        description: "Please try again",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      setBtnLoading(false);

      // onClose();

      // Handle error
    }
  };

  return (
    <Box>
      <Stack spacing={4}>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={2}>
          <MyBox>
            <FormControl isRequired>
              <FormLabel>Upload Image</FormLabel>
              <Input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                display="none"
              />
              <Image
                src={
                  previewImage ||
                  "https://placehold.co/600x400?text=Upload Image"
                }
                alt="Placeholder"
                boxSize="200px"
                objectFit="contain"
                cursor="pointer"
                onClick={handleImageClick}
              />
            </FormControl>
          </MyBox>
          <Stack>
            <MyBox>
              <FormLabel>Title</FormLabel>
              <Input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </MyBox>
            <MyBox>
              <FormLabel>Slug</FormLabel>
              <Input
                type="text"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
              />
            </MyBox>
            <MyBox>
              <FormLabel>Description</FormLabel>
              <Input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </MyBox>
            
          </Stack>
        </SimpleGrid>

        <MyBox>
          <Text fontWeight="semibold">Body</Text>
          <CustomBlog
            fields={{ showAuthor: true, showCreationDate: true }}
            onSave={handleSaveBlog}
            btnLoading={btnLoading}
          />
        </MyBox>
      </Stack>
    </Box>
  );
};

export default AddNew;
