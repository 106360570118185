import axios from "axios";
import { BASE_URL } from "./constants";
import { useToast } from "@chakra-ui/react";

export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/user/login`,
      credentials,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    throw error;
  }
};


export const refreshToken = async (token) => {
  try {
    
    const response = await axios.post(
      `${BASE_URL}/api/user/renew-token`,
      token,
      {
        headers: {
          "token": token
        },
      }
    );
    localStorage.removeItem("token");
    
    localStorage.setItem("token", response.data.token);
    return response.data.token; // Return the response data
  } catch (error) {
    throw error;
  }
};

export const tokenValidity = async (token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/user/validate-token`,
      token,
      {
        headers: {
          "token": token
        },
      }
    );
    return response.data.valid; // Return the response data
  } catch (error) {
    throw error;
  }
};


export const refreshTokenIfNeeded = async (token) => {

  if (token) {
    if (tokenValidity(token)) {
      //token is valid, no need to refresh
      return token;
    }
    else {
      localStorage.clear();
      window.location.href = "/";
    }

  }
};