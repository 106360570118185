import logo from "./logo.svg";
import "./App.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import { ChakraProvider } from "@chakra-ui/react";
import Sidebar from "./layout/Sidebar";
import { Navigate, Route, Routes } from "react-router-dom";
import theme from "./styles/theme";
import { useEffect, useState } from "react";
import SignIn from "./pages/SignIn";
import BlogManagement from "./blogManagement";
import Home from "./pages/Home";
import ParentCategory from "./pages/categories/parent/ParentCategory";
import SubCategory from "./pages/categories/sub/SubCategory";
import Footer from "./layout/Footer";
import Blog from "./pages/blog/Blog";
import User from "./pages/users/Users";
import Office from "./pages/office/Office";
import { refreshTokenIfNeeded } from "./api/auth";
import AddNew from "./pages/categories/sub/AddNew";

const token = localStorage.getItem("token");


const userLoggedIn = localStorage.getItem("isUserLoggedIn")

function App() {
  const [sideBarWidth, setSideBarWidth] = useState("large");
  const handleSidebarWidth = () => {
    setSideBarWidth((prevWidth) => (prevWidth === "small" ? "large" : "small"));
  };
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (token) {
      refreshTokenIfNeeded(token);
    }
  })



  if (!isLoggedIn && !userLoggedIn) {
    return (
      <ChakraProvider theme={theme}>
        <Routes>
          <Route path="/" element={<SignIn setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/*" element={<SignIn setIsLoggedIn={setIsLoggedIn} />} />
        </Routes>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <>
        <Sidebar
          sideBarWidth={sideBarWidth}
          handleSidebarWidth={handleSidebarWidth}
        />
        <Routes>
          <Route
            path="/"
            element={<ParentCategory sideBarWidth={sideBarWidth} />}
          />
          <Route
            path="/dashboard"
            element={<Home sideBarWidth={sideBarWidth} />}
          />
          <Route
            path="/categories/parent"
            element={<ParentCategory sideBarWidth={sideBarWidth} />}
          />
          <Route
            path="/categories/sub-category"
            element={<SubCategory sideBarWidth={sideBarWidth} />}
          />
          <Route
            path="/categories/sub-category/new"
            element={<AddNew sideBarWidth={sideBarWidth} />}
          />
          <Route path="/blogs" element={<Blog sideBarWidth={sideBarWidth} />} />
          <Route path="/users" element={<User sideBarWidth={sideBarWidth} />} />
          <Route path="/offices" element={<Office sideBarWidth={sideBarWidth} />} />
        </Routes>
        <Footer />
      </>
    </ChakraProvider>
  );
}

export default App;
