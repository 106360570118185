import React from "react";
import img1 from "../images/loader/logo_tl.png";
import img2 from "../images/loader/logo_tr.png";
import img3 from "../images/loader/logo_bl.png";
import img4 from "../images/loader/logo_br.png";
import { motion } from "framer-motion";
import { Flex, Image, SimpleGrid } from "@chakra-ui/react";

const LoaderMini = () => {
  return (
    <Flex align="center" justify="center" minH="50vh" mb="20vh">
      <SimpleGrid columns={2} gap={1} mt="20vh">
        <motion.div
          initial={{ opacity: 0.2, x: -30, y: -30 }}
          animate={{ opacity: 1, x: 0, y: 0 }}
          transition={{
            type: "spring",
            stiffness: 80,
            damping: 40,
            repeat: Infinity, // Make the animation repeat infinitely
            repeatType: "reverse",
          }}
          className="flex justify-center"
        >
          <Image src={img1} alt="" boxSize={10} />
        </motion.div>
        <motion.div
          initial={{ opacity: 0.2, x: 30, y: -30 }}
          animate={{ opacity: 1, x: 0, y: 0 }}
          transition={{
            type: "spring",
            stiffness: 80,
            damping: 40,
            repeat: Infinity, // Make the animation repeat infinitely
            repeatType: "reverse",
          }}
          className="flex justify-center"
        >
          <Image src={img2} alt="" boxSize={10} />
        </motion.div>
        <motion.div
          initial={{ opacity: 0.2, x: -30, y: 30 }}
          animate={{ opacity: 1, x: 0, y: 0 }}
          transition={{
            type: "spring",
            stiffness: 80,
            damping: 40,
            repeat: Infinity, // Make the animation repeat infinitely
            repeatType: "reverse",
          }}
          className="flex justify-center"
        >
          <Image src={img3} alt="" boxSize={10} />
        </motion.div>
        <motion.div
          initial={{ opacity: 0.2, x: 30, y: 30 }}
          animate={{ opacity: 1, x: 0, y: 0 }}
          transition={{
            type: "spring",
            stiffness: 80,
            damping: 40,
            repeat: Infinity, // Make the animation repeat infinitely
            repeatType: "reverse",
          }}
          className="flex justify-center"
        >
          <Image src={img4} alt="" boxSize={10} />
        </motion.div>
      </SimpleGrid>
    </Flex>
  );
};

export default LoaderMini;
