import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import MyBox from "../../layout/MyBox";
import { updateUser } from "../../api/user"; // Import the API function to update a user
import { FiEye, FiEyeOff } from "react-icons/fi";

const Edit = ({ selectedItem, onClose, fetchUsers }) => {
  const [formData, setFormData] = useState({
    name: selectedItem.name,
    email: selectedItem.email,
    password: "",
    role: selectedItem.role,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [togglePassword, setTogglePassword] = useState(false);

  const toast = useToast();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        setIsLoading(true);

        // Send a PUT request to the API endpoint to update the user
        await updateUser(selectedItem.id, formData);
        toast({
          title: "User updated successfully!",
          position: "top-right",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
        setIsLoading(false);

        fetchUsers();
      } catch (error) {
        console.error("Error updating user:", error);
        toast({
          title: "Error",
          description: "Failed to add user. Please try again.",
          position: "top-right",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        toast({
          // title: "I",
          description: <Text fontSize="sm">{error.response.data.message}</Text>,
          position: "top-right",
          status: "info",
          // duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = "Name is required";
    }
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }
    if (!data.password) {
      errors.password = "Password is required";
    }
    if (!data.role) {
      errors.role = "Role is required";
    }
    return errors;
  };

  return (
    <MyBox>
      <Heading size="md">Edit User</Heading>
      <form onSubmit={handleSubmit}>
        <FormControl id="name" mt={4} isInvalid={errors.name}>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>
        <FormControl id="email" mt={4} isInvalid={errors.email}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl id="password" mt={4} isInvalid={errors.password}>
          <FormLabel>Password</FormLabel>
          <Flex>
            <Input
              type={togglePassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <IconButton
              icon={togglePassword ? <FiEye /> : <FiEyeOff />}
              onClick={() => {
                setTogglePassword(!togglePassword);
              }}
            />
          </Flex>
          <FormErrorMessage>{errors.password}</FormErrorMessage>
        </FormControl>
        <FormControl id="role" mt={4} isInvalid={errors.role}>
          <FormLabel>Role</FormLabel>
          <Select
            name="role"
            value={formData.role}
            onChange={handleChange}
            placeholder="Select role"
          >
            <option value="admin">Admin</option>
            <option value="seo">SEO</option>
          </Select>
          <FormErrorMessage>{errors.role}</FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          colorScheme="primary"
          mt={4}
          isLoading={isLoading} // Set button loading state
          loadingText="Updating"
        >
          Update User
        </Button>
      </form>
    </MyBox>
  );
};

export default Edit;
