import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import AddNew from "./AddNew";
import View from "./View";
import Edit from "./Edit";

const Drawers = ({
  isOpen,
  onClose,
  drawerType,
  data,
  fetchArticles // Renamed function
}) => {
  const renderDrawer = () => {
    switch (drawerType) {
      case "addNew":
        return <AddNew onClose={onClose} fetchArticles={fetchArticles} />;
      case "show":
        return (
          <View
            selectedItem={data}
            onClose={onClose}
          // handleAddUpdateDeleteItem={handleAddUpdateDeleteItem}
          />
        );
      case "edit":
        return (
          <Edit
            selectedItem={data}
            onClose={onClose}
            fetchArticles={fetchArticles}
          // handleAddUpdateDeleteItem={handleAddUpdateDeleteItem} // Renamed function
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {drawerType === "addNew" && "Add New Blog"}
          {drawerType === "show" && "Show Blog Details"}{" "}
          {drawerType === "edit" && "Edit Blog Details"}{" "}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderDrawer()}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Drawers;
