import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import MyBox from "../../layout/MyBox";
import Page from "../../layout/Page";
import DeleteAlert from "../../components/DeleteAlert";
import Drawers from "./Drawers";
import { deleteUser, getAllUsers } from "../../api/user"; // Import the API function to fetch users
import { HiDotsVertical } from "react-icons/hi";
import { BiShow } from "react-icons/bi";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import LoaderMini from "../../components/LoaderMini";

const User = ({ sideBarWidth }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [drawerType, setDrawerType] = useState("");
  const [drawerData, setDrawerData] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [users, setUsers] = useState([]); // State variable to hold users
  const [searchTerm, setSearchTerm] = useState("");
  const [filterRole, setFilterRole] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getAllUsers();
      setUsers(fetchedUsers);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []); // Fetch users on component mount

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRoleFilter = (event) => {
    setFilterRole(event.target.value);
  };

  const openDrawer = (type, data = null) => {
    setDrawerType(type);
    setDrawerData(data);
    onOpen();
  };

  const handleDeleteUser = (userId) => {
    setDeleteUserId(userId);
  };

  const onCloseDeleteConfirmation = () => {
    setDeleteUserId(null);
  };

  if (isLoading) {
    return <LoaderMini />;
  }
  return (
    <Page sideBarWidth={sideBarWidth}>
      <MyBox>
        <Flex justify="space-between" align="center" mb={4}>
          <Heading size="md">User Management</Heading>
          <Button
            variant="solid"
            colorScheme="primary"
            size="sm"
            onClick={() => openDrawer("addNew")}
          >
            Add New User
          </Button>
        </Flex>
        <Flex align="center">
          <Input
            flex="7"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            mr={2}
          />
          <Select flex="2" value={filterRole} onChange={handleRoleFilter}>
            <option value="All">All</option>
            <option value="admin">Admin</option>
            <option value="seo">Seo</option>
          </Select>
        </Flex>
        <TableContainer>
          <Table variant="striped" size="sm" mt={4}>
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Name</Th>
                <Th>Role</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users
                .filter((user) => {
                  const matchesSearch =
                    user.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    user.email.toLowerCase().includes(searchTerm.toLowerCase());
                  const matchesRole =
                    filterRole === "All" || user.role === filterRole;
                  return matchesSearch && matchesRole;
                })
                .map((user) => (
                  <Tr key={user.id}>
                    <Td>{user.email}</Td>
                    <Td>{user.name}</Td>
                    <Td>{user.role}</Td>
                    <Td>
                      <Flex>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<HiDotsVertical />}
                            variant="ghost"
                            size="sm"
                          />
                          <MenuList>
                            <MenuItem
                              icon={<BiShow />}
                              onClick={() => openDrawer("show", user)}
                            >
                              View
                            </MenuItem>
                            <MenuItem
                              icon={<FiEdit />}
                              onClick={() => openDrawer("edit", user)}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              icon={<FiTrash2 />}
                              onClick={() => handleDeleteUser(user.id)}
                            >
                              Delete
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </MyBox>
      <Drawers
        isOpen={isOpen}
        onClose={onClose}
        drawerType={drawerType}
        data={drawerData}
        handleAddUpdateDeleteUser={null}
        fetchUsers={fetchUsers}
      />
      <DeleteAlert
        isOpen={deleteUserId !== null}
        onClose={onCloseDeleteConfirmation}
        onConfirmDelete={async () => {
          try {
            await deleteUser(deleteUserId); // Call the API function to delete user
            const updatedUsers = users.filter(
              (user) => user.id !== deleteUserId
            );
            setUsers(updatedUsers);
            onCloseDeleteConfirmation();
          } catch (error) {
            console.error("Error deleting user:", error);
            // Handle error, show error message, etc.
          }
        }}
        HeaderText="Delete User"
        BodyText="Are you sure you want to delete this user?"
      />
    </Page>
  );
};

export default User;
